import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";
import userService from '../services/user.service'

Vue.use(VueRouter)

const routes = [

 

  {
    path: '*',
    name: '404',
    component: () => import('../views/Error404'),
    meta :{
     log:false,
     admin : false,
     auth: false,
    }
  },



  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/what-we-do',
    name: 'WhatWeDoPath',
    component: () => import('../views/WhatWeDo.vue'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('../views/blog/BlogsView.vue'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/blog-es',
    name: 'blog es',
    component: () => import('../views/blog/BlogsViewEs.vue'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/blog/:url',
    name: 'blog-post',
    component: () => import('../views/blog/PostBlog.vue'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/blog-es/:url',
    name: 'blog-post es',
    component: () => import('../views/blog/PostBlog-es.vue'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },


  {
    path: '/about',
    name: 'About',
    component: () => import('../views/About'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },

  {
    path: '/maquinado-industrial',
    name: 'maquinado-industrial',
    component: () => import('../views/landing/landing'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-hermosillo',
    name: 'maquinado-industrial en hermosillo',
    component: () => import('../views/landing/landing-hermosillo'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-obregon',
    name: 'maquinado-industrial en obregon',
    component: () => import('../views/landing/landing-obregon'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-guaymas',
    name: 'maquinado-industrial en guaymas',
    component: () => import('../views/landing/landing-guaymas'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-nogales',
    name: 'maquinado-industrial en nogales',
    component: () => import('../views/landing/landing-nogales'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-cananea',
    name: 'maquinado-industrial en cananea',
    component: () => import('../views/landing/landing-cananea'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-san-luis-rio-colorado',
    name: 'maquinado-industrial en San Luis Rio Colorado',
    component: () => import('../views/landing/landing-sanLuis'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-Caborca',
    name: 'maquinado-industrial en Caborca',
    component: () => import('../views/landing/landing-caborca'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/maquinado-industrial-Navojoa',
    name: 'maquinado-industrial en Navojoa',
    component: () => import('../views/landing/landing-navojoa'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },

  {
    path: '/installation-and-commissioning',
    name: 'On-Site Robotic Commissioning & Support by DavisInd Group',
    component: () => import('../views/landing-eng/landingRobotics'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/install-and-commissioning',
    name: 'Support by DavisInd Group',
    component: () => import('../views/landing-eng/landingRobotics-var1'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/installing-and-commissioning',
    name: 'On-Site Commissioning & Support by DavisInd Group',
    component: () => import('../views/landing-eng/landingRobotics-var2'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/robot-commissioning',
    name: 'On-Site Robotic Commissioning by DavisInd Group',
    component: () => import('../views/landing-eng/landingRobotics-var3'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },

  {
    path: '/installation-and-commissioning-chicago',
    name: 'On-Site Robotic Commissioning & Support by DavisInd Group chicago',
    component: () => import('../views/landing-eng/landingRobotics-chicago'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/installation-and-commissioning-kansas',
    name: 'On-Site Robotic Commissioning & Support by DavisInd Group kansas',
    component: () => import('../views/landing-eng/landingRobotics-kansas'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/installation-and-commissioning-michigan',
    name: 'On-Site Robotic Commissioning & Support by DavisInd Group michigan',
    component: () => import('../views/landing-eng/landingRobotics-michigan'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/installation-and-commissioning-arizona',
    name: 'On-Site Robotic Commissioning & Support by DavisInd Group arizona',
    component: () => import('../views/landing-eng/landingRobotics-arizona'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/installation-and-commissioning-louisville',
    name: 'On-Site Robotic Commissioning & Support by DavisInd Group louisville',
    component: () => import('../views/landing-eng/landingRobotics-louisville'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/installation-and-commissioning-wayne',
    name: 'On-Site Robotic Commissioning & Support by DavisInd Group wayne',
    component: () => import('../views/landing-eng/landingRobotics-wayne'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  {
    path: '/aviso-de-privacidad',
    name: 'AvisoDePrivacidad',
    component: () => import('../views/landing/AvisoDePrivacidad'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },

  {
    path: '/contact',
    name: 'Contact',
    component: () => import('../views/Contact'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },


  {
    path: '/quality-policy',
    name: 'qualitypolicy',
    component: () => import('../views/QualityPolicy'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },

  {
    path: '/project/:url',
    name: 'projectfng',
    component: () => import('../views/Projects'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },


  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login'),
    meta :{
      log:false,
      admin : false,
      auth: false,
    }
  },
  
{
  path: '/auth',
  name: 'autentificacion',
  component: () => import('../views/Autentificacion'),
  meta :{
    log:true,
    admin : true,
    auth: false
  }
},
{
  path: '/administrador',
  name: 'Dashboard',
  component: () => import('../views/Dashboard'),
  meta :{
    log:true,
    admin : true,
    auth: true, 
  }
},

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Check if the route has a hash
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: 'smooth' // Optional: Add smooth scrolling behavior
      }
    }
  }
})



router.beforeEach( async (to, from, next) => {

  let meta_admin = to.matched.some(record=>record.meta.admin);
  let meta_log = to.matched.some(record=>record.meta.log);
  let meta_auth = to.matched.some(record=>record.meta.auth);


  let tokenexp = await userService.tokenexp()
  let check =false //comprobar si esta logeado
  let admin =false;
  let type =''
  let res = false;
  let emp = false;

  if(tokenexp){ // ver que el token no este expirado.
    if(meta_log){
      clearLocalStorage()  //limpiar el localstorage
    next()
    }
    
  }


  let response = await userService.getData()

  if(response.status =='error'){
    if(meta_log){
      clearLocalStorage()
    }

  }


  if(response.status=='success'){ //comprobar que sea un login valido.
    check =true;
    let isAdmin = userService.isAdmin(response.result);
    admin = isAdmin.admin
    type = isAdmin.type

    let isRes = userService.isRes(response.result);
    res = isRes.res
    let isEmp = userService.isEmp(response.result);
    emp = isEmp.emp
  
  }

  let ath = await userService.getAth();

 
  // borrar el localstorage cada que vayamos a login.
  
  if(to.path==='/login' && check){
    clearLocalStorage()
    next()
  }

 
  if(meta_log){





    if(check){
      if(meta_admin){
            if(admin){
            
              if(meta_auth){
                if(ath){
                  next();
                }else{
                   localStorage.removeItem('crt')
                localStorage.removeItem('tkn')
                localStorage.removeItem('ath')
                  next('/login')
                }
              }else{ //...proximamente rutas que no requieran autentificacion
                next()
              }
             
            }else{
               localStorage.removeItem('crt')
                localStorage.removeItem('tkn')
                localStorage.removeItem('ath')
              next('/')
            }
     }else{
        if(admin){ //no requiere admin pero es admin
          next()
         }else{
           next()
         }
      }
    }else{
      next('/login')
    }
    

  }else{
   
    if(check){
      if(admin){
       next()
      }else{
        next();
      }
    }else{
      next();
    }

  }


});


const clearLocalStorage =  function(){
  localStorage.removeItem('crt')
  localStorage.removeItem('tkn')
  localStorage.removeItem('ath')
}










export default router

