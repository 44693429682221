<template>
 <div id="app">
    <router-view />
  </div>
</template>

<script>
import {mapActions} from 'vuex';

export default {
  name: 'App',
  data(){
    return{
      colores:''
    }
  },

  async created(){
    await this.getAllInfoClr('profile')
  
    this.colores = this.profiles
     this.actualizarColores()
  },
  watch:{
      profiles(){
        
         this.actualizarColores()
        
      }
  },
  computed:{
    profiles() {
        return this.$store.getters["profile/data"];
        },
  },
  methods: {
     ...mapActions('colors', ['getAllInfoClr']),
     ...mapActions('profile', ['getAllInfoPrf']),
     actualizarColores(){
        const arr = this.$store.getters["colors/data"];
   
  
        
         if(Array.isArray(arr)){
           for (var i=0; i<arr.length; i++) {
          
             if(i==0){
                document.documentElement.style.setProperty('--color-1', arr[i].code);
             }else if(i==1){
                document.documentElement.style.setProperty('--color-2', arr[i].code);
             }else if(i==2){
               document.documentElement.style.setProperty('--color-3', arr[i].code);
             }else if(i==3){
                document.documentElement.style.setProperty('--color-4', arr[i].code);
             }else if(i==4){
              document.documentElement.style.setProperty('--color-5', arr[i].code);
             }else if(i==5){
                document.documentElement.style.setProperty('--color-6', arr[i].code);
             }else if(i==6){
                document.documentElement.style.setProperty('--color-7', arr[i].code);
             }
          }
         }
     }
  },
}
</script>

<style>

@import './css/default.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700;800&display=swap');


@font-face {
  font-family: "MADE Outer Sans Black";
  src: url("./assets/fonts/MADE-Outer-Sans-Black-PERSONAL-USE.otf");
  font-display: swap;
}



@font-face {
  font-family: "MADE Outer Sans Thin";
  src: url("./assets/fonts/MADE-Outer-Sans-Thin-PERSONAL-USE.otf");
  font-display: swap;
}



@font-face {
  font-family: "MADE Outer Sans Regular";
  src: url("./assets/fonts/MADE-Outer-Sans-Regular-PERSONAL-USE.otf");
  font-display: swap;
}



@font-face {
  font-family: "MADE Outer Sans Medium";
  src: url("./assets/fonts/MADE-Outer-Sans-Medium-PERSONAL-USE.otf");
  font-display: swap;
}



@font-face {
  font-family: "MADE Outer Sans Light";
  src: url("./assets/fonts/MADE-Outer-Sans-Light-PERSONAL-USE.otf");
  font-display: swap;
}



@font-face {
  font-family: "MADE Outer Sans Bold";
  src: url("./assets/fonts/MADE-Outer-Sans-Bold-PERSONAL-USE.otf");
  font-display: swap;
}

:root {
  --color-1: #06C5FF;
  --color-2: #262FFD;
  --color-3: #676467;
  --color-4: #8d8d8d;
  --color-5: #ffffff;
  --color-6: #76E2FF;
  --color-7: #196BFE;
 

  --OpenSans: 'Open Sans', sans-serif;
  --MOSB_Black :   "MADE Outer Sans Black";
  --MOSB_Thin :   "MADE Outer Sans Thin";
  --MOSB_Regular :   "MADE Outer Sans Regular";
  --MOSB_Medium :   "MADE Outer Sans Medium";
  --MOSB_Light :   "MADE Outer Sans Light";
  --MOSB_Bold :   "MADE Outer Sans Bold";


--font-titulo1:'MADE Outer Sans Regular';
--fs-menupc:0.677vw;
--co-menui:#292929;
}

#app {
  margin: 0vw;
  height: 100%;
  font-family: var(--OpenSans);
}
body{
  margin: 0vw;
}

html {
  scroll-behavior: smooth;
}

/* INICIO CSS CAROUSEL HOME B6 */

.B6Content .VueCarousel-wrapper {
  position: relative;
  overflow: hidden;
  width: 76.9VW;
  height: fit-content;
}
.B6Content .VueCarousel-slide, .B6Slide {
  position: relative;
  overflow: hidden;
  width: 75.5VW;
  height: 154.90654205607478VW;
  border: 0.1vw solid var(--color-3);
  margin: 0.4vw;
}
.B6Content .divPrev0 {
  width: 12.383177570093459VW;
  height: 12.383177570093459VW;
  background-color: var(--color-2);
  border-radius: 10vw;
  /*display: flex;
  justify-content: center;
  align-items: center;*/
}
.B6Content .VueCarousel-navigation {
  width: 63vw;
  height: 15vw;
  position: absolute;
  margin-left: 8vw;
  display: flex;
  margin-top: 68vw;
}
.B6Content p.NButton {
  /*height: 11vw;*/
    height: 2vw;
    width: 100%;
  font-size: 7vw;
    line-height: 0;
  color: var(--color-5);
  margin: 0vw;
    position: relative;
    top: 5vw;
}
.B6Content .VueCarousel-inner {
  justify-content: space-between;
  flex-basis: auto !important;
}
@media (max-width: 768px) {
 .B1F2 .agile  {
    width: 78vw;
}
}
@media (min-width: 768px) {

  .B6Content .VueCarousel-wrapper {
    width: 78.03738317757009VW;
  }

  .B6Content .VueCarousel-inner {
    justify-content: space-between;
    flex-basis: auto !important;
  }

  .B6Content .VueCarousel-slide, .B6Slide {
    width: 24.739583333333336VW;
    /*height: 47.1875VW; */ 
    height: fit-content;
    margin: 0.5vw;
  }
  .B6Slide { 
    padding-bottom: 1.841121vw;
}


}

/* FIN CSS CAROUSEL HOME B6 */



.dflx{
  display: flex;
}
.cp{
  cursor: pointer;
}
</style>
