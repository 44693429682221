import Vue from 'vue'
import Vuex from 'vuex'

import admin from './modules/admin'
import usuarios from './modules/usuarios'
import main from './modules/main'
import colors from './modules/colors'
import profile from './modules/profile'
import logo from './modules/logo'
import category from  './modules/category'
import tag from  './modules/tag'
import colaboradores from './modules/colaboradores';
import blog from './modules/blog'
import blog_category from './modules/blog_category'
import project_category from  './modules/project_category'
import project from './modules/project'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    admin: admin,
    main:main,
    usuarios:usuarios,
    profile:profile,
    colors:colors,
    logo:logo,
    category:category,
    tag:tag,
    colaboradores:colaboradores,
    blog:blog,
    blog_category:blog_category,
    project_category:project_category,
    project:project



  }
})
